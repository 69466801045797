<template>
<!-- 联系我们 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/4.jpg" >
    </div>
   <div class="module-content">
      <el-row>
        <el-col :span="12">
            <div class="nuox-breadcrumb">
              <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
              <span class="iconfont icon-dian"></span>
              <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[7].name") }}</span>
            </div>
        </el-col>
        <el-col :span="12"><div class="text-blue" style="text-align:right"> 投递简历邮箱：hr@nxyc.site</div></el-col>
      </el-row>
      <div class="module-line"></div>
      <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[7].name") }}
              <div class="xian"></div>
            </div>
      </div>
    <div class="nuox-jn-module">
      <div v-for="(item,index) in datas" :key="index" class="join-nuox-job">
          <div class="nuox-title-two" style="margin-top:10px;margin-bottom:25px" v-if="$i18n.locale=='zh'">
                  <i class="iconfont icon-shuxian"></i>{{item.contactTitleZh}}<i class="iconfont icon-shuxian"></i>
          </div>
          <div v-html="item.contactContentZh" v-if="$i18n.locale=='zh'"></div>
          <div class="nuox-title-two" style="margin-top:50px;margin-bottom:25px" v-if="$i18n.locale=='en'">
                  <i class="iconfont icon-shuxian"></i>{{item.contactTitleEn}}<i class="iconfont icon-shuxian"></i>
          </div>
          <div v-html="item.contactContentEn" v-if="$i18n.locale=='en'"></div>
      </div>
    </div>
         <div class="module-line"></div>
     </div>
  </div>
</template>
<script>
export default {
  data:function() {
    return {
        menus:[],
        checkId:32,
        checkChildrenId:0,
        pageNum:1,
        pageCount:80,
        datas:[]
    };
  },
  mounted(){
    this.init();
  },
  methods:{
    async init(){
       var that = this;
      const { data: res } = await this.$http.get(
        "webSite/contact_us?pageNum="+that.pageNum+"&pageCount="+that.pageCount
      );
      if(res.code==0){
          console.log( res.data.data);
          that.datas = res.data.data;
      }
    },
    checknav(e){
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
      this.checkId = e.id;
    },
    checknavchildren(item,item2){
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    }
  }
}
</script>
<style>
@import '../assets/css/about.css';
.nuox-jn-module{
    margin: 50px auto;
}
.nuox-jn-module table {
  width: 100%;
}
.nuox-jn-module table td{
  width: 50% !important;
  vertical-align:text-top;
}
.join-nuox-job{
  padding: 10px;
}
.join-nuox-job:hover{
  border-radius: 5px;
  padding: 10px;
  background-image: linear-gradient(45deg, #E7EEF4, #E7EEF4);
}
</style>